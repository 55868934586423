import * as actions from '../actions/actionTypes';

export default function currentLegalBasis(state = 0, action) {
  switch (action.type) {
    case actions.CHANGE_CURRENT_LEGAL_BASIS:
      return action.payload.id;
    default:
      return state;
  }
}
