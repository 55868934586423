import React from 'react';
import allLegalBasis from './data/allLegalBasis';
import { useSelector } from 'react-redux';
import { FaRedo, FaRegFilePdf } from 'react-icons/fa';
import './Result.css';

export default function History() {
  const history = useSelector((state) => state.questionnaireHistory);
  // const activityDescription = useSelector((state) => state.activityDescription);

  const currentLegalBasisNumber = useSelector(
    (state) => state.currentLegalBasis
  );

  const currentLegalBasis = allLegalBasis.filter(
    (question) => question.id === currentLegalBasisNumber
  );

  return (
    <>
      <img
        id='headerPrint'
        style={{ height: '19pt', marginTop:'0%', }}
        className='print'
        src='https://pradovidigal.com.br/wp-content/uploads/2020/10/logo.png'
        alt=''
      />
      <div className='content-block' id='pdf' style={{ width: '100%' }}>
        <div
          className='base'
          style={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: 'white',
            background: '#2E382E',
            border: '3px solid #2E382E',
            textAlign: 'left',
            //height:'300px'
          }}>
          {/* icon */}
          <h1>
            <i
              class={currentLegalBasis[0].icon}
              style={{
                padding: '10px',
              }}></i>
          </h1>

          <h2 className='currentLegalBasis' style={{ padding: '5px' }}>
            {currentLegalBasis[0].title}
          </h2>
          <p
            className='currentLegalBasisDescription'
            style={{ padding: '5px' }}>
            {currentLegalBasis[0].description}
          </p>
          <div className='obs1' style={{ padding: '5px' }}>
            {currentLegalBasis[0].obs}
          </div>
        </div>

        <div
          className='respostas'
          style={{
            border: '3px solid #2E382E',
            padding: '10px',
            marginBottom: '20px',
          }}>
          <h2 style={{ textAlign: 'center', paddingBottom: '6px' }}>
            Histórico de perguntas
          </h2>
          {/* <p style={{ padding: '6px' }}>
            1.Descreva sua atividade:
            <br />
            <span style={{ fontWeight: 'normal' }}>{activityDescription}</span>
          </p> */}

          {history.length > 0 &&
            history.map((q, i) => (
              <p key={i} style={{ padding: '4px' }}>
                {i + 1}.{q.optionQuestion}
                <br />
                <span style={{ fontWeight: 'normal' }}>{q.optionAnswer}</span>
              </p>
            ))}
        </div>
      </div>
      <div className='resultButtonsDiv noPrint'>
        <button
          className='button'
          onClick={() => {
            document.location.reload();
          }}>
          REFAZER <FaRedo />
        </button>

        <button
          className='button'
          onClick={() => {
            window.print();
          }}>
          GERAR RELATÓRIO <FaRegFilePdf />
        </button>
      </div>
      <p id='footerPrint' className='print' style={{ margin: '5px', width: '98.5%', lineHeight: '1.2em', marginBottom:'0%' }}>
      Essa ferramenta [baselegal.pradovidigal.com.br], desenvolvida pelo escritório Prado Vidigal Advogados, serve de auxílio ao processo de definição de bases legais para atividades de tratamento de dados pessoais. Seus resultados são gerados automaticamente a partir das respostas do usuário e constituem mero ponto de partida, não configurando – em hipótese alguma – pareceres ou opiniões legais do escritório; logo, devem sempre ser confirmados antes de adotados por usuários da ferramenta.
      Não acessaremos, armazenaremos ou divulgaremos quaisquer informações inseridas ao longo da interação dos usuários com a ferramenta ou, ainda, constante de seus resultados, cabendo aos usuários zelar pelo bom uso e guarda de eventuais relatórios extraídos da ferramenta.
      </p>
    </>
  );
}
