import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

export default function Disclaimer() {
  return (
    <div className="noPrint" style={style1}>
      <h2 style={{ color: '#FF625F' }}>
        <FaExclamationTriangle />
      </h2>
      <p style={{ textAlign: 'left', fontSize: '12px' }}>
      Essa ferramenta [baselegal.pradovidigal.com.br], desenvolvida pelo escritório Prado Vidigal Advogados, serve de auxílio ao processo de definição de bases legais para atividades de tratamento de dados pessoais. Seus resultados são gerados automaticamente a partir das respostas do usuário e constituem mero ponto de partida, não configurando – em hipótese alguma – pareceres ou opiniões legais do escritório; logo, devem sempre ser confirmados antes de adotados por usuários da ferramenta. {' '}
      </p>
      <p style={{ textAlign: 'left', fontSize: '12px' }}>
      Não acessaremos, armazenaremos ou divulgaremos quaisquer informações inseridas ao longo da interação dos usuários com a ferramenta ou, ainda, constante de seus resultados, cabendo aos usuários zelar pelo bom uso e guarda de eventuais relatórios extraídos da ferramenta. {' '}
      </p>
    </div>
  );
}

const style1 = {
  padding: '10px',
  margin: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'center',
};
