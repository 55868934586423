import React from 'react';
import Main from './Main';
import { FaArrowLeft } from 'react-icons/fa';
import './Body.css';
import logo from './prado_vidigal.png'

export default function Body() {
  return (
    <div className="wholeBody" style={{ ...fundo }}>
      <button className="homeLink noPrint">
        <a href="https://pradovidigal.com.br/" style={{ ...flexCenterCenter }}>
          <span style={{ color: '#FF625F', ...flexCenterCenter }}>
            <FaArrowLeft />
          </span>
          <img 
          src={logo}
          width='200px'
          style={{paddingLeft: '10px'}}
          alt="Logo do escritório de advocacia Prado Vidigal"
          />
        </a>
      </button>
      <Main />
    </div>
  );
}

const flexCenterCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const fundo = {
  backgroundColor: '#e0e0e0',
};
