const allQuestions = [
  // Primeira pergunta questão 33 e altera em currentQuestion.js (state = 33).
  {
    id: 1,
    question: 'A atividade envolve a:',
    answers: [
      {
        answer: 'Obtenção de novos dados',
        goTo: ['question', 5],
      },
      {
        answer: 'Utilização de dados já coletados para outra finalidade',
        goTo: ['question', 32],
      },
    ],
  },
  {
    id: 2,
    question: 'A nova finalidade é compatível com a original?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 3],
      },
      {
        answer: 'Não',
        goTo: ['question', 5],
      },
    ],
  },
  {
    id: 3,
    question: 'Qual a base legal que fundamenta a finalidade anterior?',
    answers: [
      {
        answer: 'Consentimento',
        goTo: ['question', 4],
      },
      {
        answer: 'Outra',
        goTo: ['legalBasis', 1],
      },
    ],
  },
  {
    id: 4,
    question: 'A atividade envolve dados sensíveis?',
    obs: 'São dados sensíveis: dados sobre origem racial ou étnica, religão/crença, filiação a sindicato, organização religiosa, filosófica ou política, dados de saúde, dados referentes à vida sexual, dados genéticos ou biométricos',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 31],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 25],
      },
    ],
  },
  {
    id: 5,
    question: 'A atividade envolve dados sensíveis?',
    obs: 'São dados sensíveis: dados sobre origem racial ou étnica, religão/crença, filiação a sindicato, organização religiosa, filosófica ou política, dados de saúde, dados referentes à vida sexual, dados genéticos ou biométricos',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 30],
      },
      {
        answer: 'Não',
        goTo: ['question', 6],
      },
    ],
  },
  {
    id: 6,
    question: 'A atividade tem por finalidade viabilizar política pública?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 28],
      },
      {
        answer: 'Não',
        goTo: ['question', 7],
      },
    ],
  },
  {
    id: 7,
    question:
      'A atividade tem por finalidade realizar estudos por órgão de pesquisa?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 5],
      },
      {
        answer: 'Não',
        goTo: ['question', 8],
      },
    ],
  },
  {
    id: 8,
    question: 'A atividade tem por finalidade a tutela da saúde?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 6],
      },
      {
        answer: 'Não',
        goTo: ['question', 9],
      },
    ],
  },
  {
    id: 9,
    question: 'A atividade tem por finalidade cumprir lei ou regulação?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 7],
      },
      {
        answer: 'Não',
        goTo: ['question', 10],
      },
    ],
  },
  {
    id: 10,
    question:
      'A atividade tem por finalidade a execução ou realização de procedimentos preliminares a um contrato de que é parte o titular de dados?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 8],
      },
      {
        answer: 'Não',
        goTo: ['question', 11],
      },
    ],
  },
  {
    id: 11,
    question:
      'A atividade tem por finalidade o exercício de direitos em processos judiciais, administrativos ou arbitrais?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 9],
      },
      {
        answer: 'Não',
        goTo: ['question', 12],
      },
    ],
  },
  {
    id: 12,
    question:
      'A atividade tem por finalidade a proteção da vida ou integridade física de alguém?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 10],
      },
      {
        answer: 'Não',
        goTo: ['question', 13],
      },
    ],
  },
  {
    id: 13,
    question: 'A atividade tem por finalidade a proteção do crédito?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 11],
      },
      {
        answer: 'Não',
        goTo: ['question', 21],
      },
    ],
  },
  {
    id: 14,
    question: 'A atividade tem por finalidade viabilizar política pública?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 12],
      },
      {
        answer: 'Não',
        goTo: ['question', 15],
      },
    ],
  },
  {
    id: 15,
    question:
      'A atividade tem por finalidade realizar estudos por órgão de pesquisa?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 13],
      },
      {
        answer: 'Não',
        goTo: ['question', 16],
      },
    ],
  },
  {
    id: 16,
    question: 'A atividade tem por finalidade a tutela da saúde?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 14],
      },
      {
        answer: 'Não',
        goTo: ['question', 17],
      },
    ],
  },
  {
    id: 17,
    question: 'A atividade tem por finalidade cumprir lei ou regulação?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 15],
      },
      {
        answer: 'Não',
        goTo: ['question', 18],
      },
    ],
  },
  {
    id: 18,
    question:
      'A atividade tem por finalidade o exercício de direitos em processos judiciais, administrativos ou arbitrais ou, ainda, relativos a contratos?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 16],
      },
      {
        answer: 'Não',
        goTo: ['question', 19],
      },
    ],
  },
  {
    id: 19,
    question:
      'A atividade tem por finalidade a proteção da vida ou integridade física de alguém?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 17],
      },
      {
        answer: 'Não',
        goTo: ['question', 20],
      },
    ],
  },
  {
    id: 20,
    question:
      'A atividade tem por finalidade prevenção a fraudes ou garantia de segurança do titular de dados do titular de dados?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 18],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 19],
      },
    ],
  },
  {
    id: 21,
    question: 'Para atingir os objetivos da sua atividade, seria melhor:',
    answers: [
      {
        answer:
          'Deixar o titular de dados escolher se quer ou não ter seus dados tratados, permitindo inclusive que mude de opinião futuramente.',
        goTo: ['question', 25],
      },
      {
        answer:
          'Não depender da vontade do titular de dados para viabilizar a atividade',
        goTo: ['question', 22],
      },
    ],
  },
  {
    id: 22,
    question:
      'Trata-se de uma situação concreta? (i.e., não é um caso de “pode ser que, no futuro...”)',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 23],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 26],
      },
    ],
  },
  {
    id: 23,
    question:
      'Seria possível realizar a atividade com menos dados (tanto em volume como em tipologia)?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 26],
      },
      {
        answer: 'Não',
        goTo: ['question', 24],
      },
    ],
  },
  {
    id: 24,
    question:
      'O titular sabe/espera que a atividade ocorra da forma como pretendida pelo controlador/terceiros?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 30],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 26],
      },
    ],
  },
  {
    id: 25,
    question: 'Há desequilíbrio na relação entre a organização e os titulares?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 20],
      },
      {
        answer: 'Não',
        goTo: ['question', 27],
      },
    ],
  },
  {
    id: 26,
    question:
      ' O titular pode deixar de fornecer o consentimento e, ainda assim, adquirir serviço ou produto que deseja?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 27],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 21],
      },
    ],
  },
  {
    id: 27,
    question:
      ' Há janela de oportunidade para obter a autorização do titular e informá-lo sobre o tratamento?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 29],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 22],
      },
    ],
  },
  {
    id: 28,
    question:
      'Está preparado para eventualmente o titular de dados não autorizar?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 29],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 23],
      },
    ],
  },
  {
    id: 29,
    question:
      'Será possível interromper o tratamento no futuro, caso o titular mude de ideia?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 3],
      },
      {
        answer: 'Não',
        goTo: ['legalBasis', 23],
      },
    ],
  },
  {
    id: 30,
    question: 'Selecione todas as opções aplicáveis: ',
    answers: [
      {
        answer: 'Origem racial ou étnica',
        goTo: ['question', 14],
      },
      {
        answer: 'Religião/Crença',
        goTo: ['question', 14],
      },
      {
        answer:
          'Filiação a sindicato, organização religiosa, filosófica ou política',
        goTo: ['question', 14],
      },
      {
        answer: 'Dados de saúde',
        goTo: ['question', 14],
      },
      {
        answer: 'Dados referentes à vida sexual',
        goTo: ['question', 14],
      },
      {
        answer: 'Dados genéticos ou biométricos',
        goTo: ['question', 14],
      },
    ],
  },
  {
    id: 31,
    question: 'Selecione todas as opções aplicáveis',
    answers: [
      {
        answer: 'Origem racial ou étnica',
        goTo: ['legalBasis', 27],
      },
      {
        answer: 'Religião/Crença',
        goTo: ['legalBasis', 27],
      },
      {
        answer:
          'Filiação a sindicato, organização religiosa, filosófica ou política',
        goTo: ['legalBasis', 27],
      },
      {
        answer: 'Dados de saúde',
        goTo: ['legalBasis', 27],
      },
      {
        answer: 'Dados referentes à vida sexual',
        goTo: ['legalBasis', 27],
      },
      {
        answer: 'Dados genéticos ou biométricos',
        goTo: ['legalBasis', 27],
      },
    ],
  },
  {
    id: 32,
    question:
      'Já houve identificação de base legal relacionada à coleta dos dados (“finalidade original”)?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['question', 2],
      },
      {
        answer: 'Não',
        goTo: ['question', 5],
      },
    ],
  },
  {
    id: 33,
    question:
      'A atividade tem como alvo o tratamento de dados pessoais de crianças (menores de 12 anos completos)?',
    answers: [
      {
        answer: 'Sim',
        goTo: ['legalBasis', 29],
      },
      {
        answer: 'Não',
        goTo: ['question', 1],
      },
    ],
  },
];

export default allQuestions;
