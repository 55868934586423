import React from 'react';
import Questions from './Questions';
import Disclaimer from './Disclaimer';
import Header from './Header';
import './Main.css';

export default function Main() {
  return (
    <div className="main" style={{ backgroundColor: '#F2E7E3' }}>
      <Header />
      <Questions />
      <Disclaimer />
    </div>
  );
}
