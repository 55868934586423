import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Body from './Body';
import store from './redux/store';

console.log(store.getState());

ReactDOM.render(
  <Provider store={store}>
    <Body />
  </Provider>,
  document.getElementById('root')
);
