const allLegalBasis = [
  {
    id: 1,
    title:
      'Base legal: Parece-nos que é possível utilizar a mesma base legal já mapeada.',
    description: '',
    icon: 'fas fa-sync',
  },
  {
    id: 2,
    title: 'BASE LEGAL: Consentimento (art. 11, I, LGPD).',
    description:
      'Obs.: obter nova autorização específica do titular dos dados.',
    icon: 'fas fa-thumbs-up',
  },
  {
    id: 3,
    title: 'BASE LEGAL: Consentimento (art. 7º, I, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada requer a autorização do titular de dados.',
    obs: 'Atenção: A autorização tem de ser obtida de maneira informada (após prestadas informações sobre o tratamento ao titular de dados), livre (a partir de uma escolha real do titular de dados) e inequívoca (a partir de uma ação afirmativa do titular; ou seja, não decorre do silêncio ou falta de ação do titular).   O consentimento deve ser registrado para fins de prova futura. Deve ser assegurada a revogação do consentimento, gratuitamente e a qualquer tempo.',
    icon: 'fas fa-thumbs-up',
  },
  {
    id: 4,
    title:
      'BASE LEGAL: Execução de políticas públicas (art. 11, II, ’b‘, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a execução de uma política pública prevista em lei ou regulamento ou, ainda, respaldada em contrato, convênio ou instrumento semelhante.',
    obs: 'Atenção: Esta base legal é reservada a integrantes da Administração Pública (direta ou indireta) ou vinculados. Exemplo: órgãos públicos autarquias, fundações, sociedades de economia mista, empresas públicas, empresas privadas prestadoras de serviço público.',
    icon: 'fas fa-users',
  },
  {
    id: 5,
    title: 'BASE LEGAL: Estudos por órgão de pesquisa (art. 7º, IV, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a realização de estudos por órgão de pesquisa. ',
    obs: 'Atenção: Esta base legal é reservada a órgão de pesquisa (órgão ou entidade da Administração Pública direta ou indireta ou pessoa jurídica de direito privado sem fins lucrativos legalmente constituída sob as leis brasileiras, com sede e foro no país, que inclua em sua missão institucional ou em seu objetivo social ou estatutário a pesquisa básica ou aplicada de caráter histórico, científico, tecnológico ou estatístico. Deve-se buscar a anonimização dos dados tratados.',
    icon: 'fas fa-chart-bar',
  },
  {
    id: 6,
    title: 'BASE LEGAL: Tutela da Saúde (art. 7º, VIII, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a tutela da saúde.',
    obs: 'Atenção: Esta base legal é reservada a profissional da saúda, serviços de saúde ou autoridade sanitária. Exemplos: médicos, enfermeiros, farmacêuticos, psicólogos, nutricionistas, profissionais de educação física, dentistas, hospitais, clínicas, consultórios, laboratórios etc.',
    icon: 'fas fa-procedures',
  },
  {
    id: 7,
    title:
      'BASE LEGAL: Cumprimento de obrigação legal ou regulatória (art. 7º, II, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade o cumprimento de obrigação prevista em lei, regulação ou ordens de autoridades competentes.',
    obs: 'Atenção: Para utilização da base legal em questão, é preciso identificar e documentar especificamente a lei, regulação ou ordem que demanda o tratamento. ',
    icon: 'fas fa-balance-scale-right',
  },
  {
    id: 8,
    title: 'BASE LEGAL: Execução de contrato (art. 7º, V, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada ocorre para que contrato com o titular de dados ou procedimentos preliminares relacionados a tal contrato possam ser executados.',
    obs: 'Atenção: Certifique-se de utilizar somente os dados necessários para viabilizar o contrato firmado ou pretendido.',
    icon: 'fas fa-file-contract',
  },
  {
    id: 9,
    title: 'BASE LEGAL: Exercício regular de direitos (art. 7º, VI, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade o exercício regular de direitos.',
    obs: 'Atenção: O exercício do direito identificado não pode se mostrar excessivo, a ponto de configurar abuso de direito. Assim, mais do que identificar o direito que justifica o tratamento de dados, é preciso garantir que o tratamento não implica riscos excessivos ou desproporcionais ao titular de dados.',
    icon: 'fas fa-gavel',
  },
  {
    id: 10,
    title:
      'BASE LEGAL: Proteção da vida / incolumidade física (art. 7º, VII, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais ocorre para que se proteja a vida ou integridade física de alguém.',
    icon: 'fa-hand-holding-heart',
  },
  {
    id: 11,
    title: 'BASE LEGAL: Proteção do crédito (art. 7º, X, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a proteção do crédito.',
    obs: 'Atenção: A atividade amparada nesta base legal tem de se destinar, diretamente, à garantia da higidez do mercado de crédito. Assim, processos que visam aos interesses comerciais da organização, ainda que possam acabar conferindo – indiretamente – maior segurança ao mercado de crédito, podem não encontrar respaldo nesta base.',
    icon: 'fas fa-credit-card',
  },
  {
    id: 12,
    title:
      'BASE LEGAL: Execução de políticas públicas (art. 11, II, ‘b’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a execução de uma política pública prevista em lei ou regulamento ou, ainda, respaldada em contrato, convênio ou instrumento semelhante.',
    obs: 'Atenção: Esta base legal é reservada a integrantes da Administração Pública (direta ou indireta) ou vinculados. Exemplo: órgãos públicos autarquias, fundações, sociedades de economia mista, empresas públicas, empresas privadas prestadoras de serviço público. A atividade tem de ser indispensável para a viabilização da política pública.',
    icon: 'fas fa-users',
  },
  {
    id: 13,
    title:
      'BASE LEGAL: Estudos por órgão de pesquisa (art. 11, II, ‘c’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a realização de estudos por órgão de pesquisa.',
    obs: 'Atenção: Esta base legal é reservada a órgão de pesquisa (órgão ou entidade da Administração Pública direta ou indireta ou pessoa jurídica de direito privado sem fins lucrativos legalmente constituída sob as leis brasileiras, com sede e foro no país, que inclua em sua missão institucional ou em seu objetivo social ou estatutário a pesquisa básica ou aplicada de caráter histórico, científico, tecnológico ou estatístico. A atividade tem de ser indispensável à realização dos estudos pretendidos. Deve-se buscar a anonimização dos dados tratados.',
    icon: 'fas fa-chart-bar',
  },
  {
    id: 14,
    title: 'BASE LEGAL: Tutela da saúde (art. 11, II, ‘f’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a tutela da saúde.',
    obs: 'Atenção: Esta base legal é reservada a profissional da saúde, serviços de saúde ou autoridade sanitária. Exemplos: médicos, enfermeiros, farmacêuticos, psicólogos, nutricionistas, profissionais de educação física, dentistas, hospitais, clínicas, consultórios, laboratórios etc. A atividade tem de ser indispensável para a tutela da saúde.',
    icon: 'fas fa-procedures',
  },
  {
    id: 15,
    title:
      'BASE LEGAL: Cumprimento de obrigação legal ou regulatória (art. 11, II, ‘a’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade o cumprimento de obrigação prevista em lei, regulação ou ordens de autoridades competentes.',
    obs: 'Atenção: Para utilização da base legal em questão, é preciso identificar e documentar especificamente a lei, regulação ou ordem que demanda o tratamento. A atividade tem de ser indispensável para o cumprimento de lei ou regulação.',
    icon: 'fas fa-balance-scale-right',
  },
  {
    id: 16,
    title:
      'BASE LEGAL: Exercício regular de direitos, inclusive em contratos (art. 11, II, ‘d’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade o exercício regular de direitos.',
    obs: 'Atenção: O exercício do direito identificado não pode se mostrar excessivo, a ponto de configurar abuso de direito. Assim, mais do que identificar o direito que justifica o tratamento de dados, é preciso garantir que o tratamento não implica riscos excessivos ou desproporcionais ao titular de dados.',
    icon: 'fas fa-file-contract',
  },
  {
    id: 17,
    title:
      'BASE LEGAL: Proteção da vida / incolumidade física (art. 11, II, ‘e’, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais ocorre para que se proteja a vida ou integridade física de alguém.',
    obs: 'Atenção: A atividade tem de ser indispensável para a proteção da vida ou integridade física.',
    icon: 'fas fa-life-ring',
  },
  {
    id: 18,
    title:
      'BASE LEGAL: Prevenção a fraude / garantia da segurança do titular (art. 11, II, ‘g’, LGPD.',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a prevenção a fraude ou garantia da segurança do titular de dados.',
    obs: 'Atenção: A atividade tem de ser indispensável para o alcance dos objetivos mencionados acima.',
    icon: 'fas fa-user-shield',
  },
  {
    id: 19,
    title: ' BASE LEGAL: Consentimento (art. 11, I, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada requer a autorização do titular de dados.',
    obs: 'Atenção: A autorização tem de ser obtida de maneira informada (após prestadas informações sobre o tratamento ao titular de dados), livre (a partir de uma escolha real do titular de dados), específica (com a definição exata das finalidades) e em destaque (em separado de eventuais termos contratuais ou outros documentos). O consentimento deve ser registrado para fins de prova futura. Deve ser assegurada a revogação do consentimento, gratuitamente e a qualquer tempo.',
    icon: 'fas fa-thumbs-up',
  },
  {
    id: 20,
    title:
      'Tudo até agora conduzia para a utilização do consentimento (art. 7º, I, LGPD). Contudo, parece que os titulares de dados podem não conseguir manifestar o consentimento de maneira livre. Portanto, a questão merece uma análise mais aprofundada.',
    description: '',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 21,
    title:
      'Tudo até agora conduzia para a utilização do consentimento (art. 7º, I, LGPD). Contudo, condicionar a aquisição de serviços/produtos ao fornecimento do consentimento pode configurar vício de vontade, inviabilizando o uso da base legal em questão. Portanto, a questão merece uma análise mais aprofundada.',
    description: '',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 22,
    title:
      'Tudo até agora conduzia para a utilização do consentimento (art. 7º, I, LGPD). Contudo, parece haver desafio operacional para a coleta do consentimento, o que pode inviabilizar sua utilização. Portanto, a questão merece uma análise mais aprofundada.',
    description: '',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 23,
    title:
      'Tudo até agora conduzia para a utilização do consentimento (art. 7º, I, LGPD). Contudo, depender da vontade do titular pode inviabilizar o objetivo pretendido com a atividade. Portanto, a questão merece uma análise mais aprofundada.',
    description: '',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 24,
    title: 'BASE LEGAL: legítimo interesse (art. 7º, IX, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada visa atingir um interesse legítimo da organização.',
    obs: 'Atenção: Devem ser tratados somente os dados pessoais estritamente necessários para o alcance do interesse. A transparência deve ser reforçada, de modo que o titular de dados possa ter ciência de como e por que o tratamento ocorre. Deve-se verificar se é preciso realizar teste de proporcionalidade (LIA) e relatório de impacto (RIPD).',
    icon: 'fas fa-hands-helping',
  },
  {
    id: 25,
    title: 'BASE LEGAL: Consentimento (art. 7º, I, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada requer a autorização do titular de dados.',
    obs: 'Atenção: comunicar o titular dos dados acerca da nova finalidade, possibilitando a revogação do consentimento dado anteriormente',
    icon: 'fas fa-thumbs-up',
  },
  {
    id: 26,
    title:
      'Parece que você está querendo utilizar o legítimo interesse (art. 7º, IX, LGPD), mas não tem segurança de que este é forte o suficiente. Certifique-se de que há, de fato, o legítimo interesse pretendido, considerando os elementos trazidos pelo artigo 10 da LGPD.',
    description: '',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 27,
    title: ' BASE LEGAL: Consentimento (art. 11, I, LGPD).',
    description:
      'A atividade de tratamento de dados pessoais analisada requer a autorização do titular de dados.',
    obs: 'Atenção: obter nova autorização do titular dos dados, de maneira informada (após prestadas informações sobre o tratamento ao titular de dados), livre (a partir de uma escolha real do titular de dados), específica (de forma expressa) e em destaque. O consentimento deve ser registrado para fins de prova futura. Deve ser assegurada a revogação do consentimento, gratuitamente e a qualquer tempo.',
    icon: 'fas fa-hands-helping',
  },
  {
    id: 28,
    title: 'BASE LEGAL: Execução de políticas públicas (art. 7º, III, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por finalidade a execução de uma política pública prevista em lei ou regulamento ou, ainda, respaldada em contrato, convênio ou instrumento semelhante.',
    obs: 'Atenção: Esta base legal é reservada a integrantes da Administração Pública (direta ou indireta) ou vinculados. Exemplo: órgãos públicos autarquias, fundações, sociedades de economia mista, empresas públicas, empresas privadas prestadoras de serviço público.',
    icon: 'fas fa-hands-helping',
  },
  {
    id: 29,
    title: 'Base Legal: Consentimento (art. 14, §1º, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada requer a autorização de um dos pais ou do responsável dos menores.',
    obs: 'Atenção: A autorização tem de ser obtida de maneira informada (após prestadas informações sobre o tratamento ao titular de dados), livre (a partir de uma escolha real do titular de dados), específica (com a definição exata das finalidades) e em destaque (em separado de eventuais termos contratuais ou outros documentos). O consentimento deve ser registrado para fins de prova futura. Deve ser assegurada a revogação do consentimento, gratuitamente e a qualquer tempo.',
    icon: 'fas fa-hands-helping',
  },
  {
    id: 30,
    title: 'Base Legal: Legítimo Interesse (art. 7º, IX, LGPD)',
    description:
      'A atividade de tratamento de dados pessoais analisada tem por fundamento o interesse legítimo. Para tanto, certifique-se de que se trata de interesse concreto, de que há uso mínimo de dados (somente o necessário) e máxima garantia de transparência ao titular de dados, além é claro do cumprimento dos demais princípios trazidos na LGPD.',
    obs: '',
    icon: 'fas fa-hands-helping',
  },
];

export default allLegalBasis;
