import * as actions from '../actions/actionTypes';
import { produce } from 'immer';

const initialState = [];

export default function questionnaireHistory(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_TO_HISTORY:
      return produce(state, (draftState) => {
        draftState.push(action.payload.description);
      });

    case actions.REMOVE_FROM_HISTORY:
      return state.filter(
        (row) => row.currentQuestionNumber !== action.payload.id
      );

    default:
      return state;
  }
}
