import './Questions.css';
import React, { useState } from 'react';
import allQuestions from './data/allQuestions';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeCurrentQuestion,
  changeCurrentLegalBasis,
  changeCurrentType,
  // changeActivityDescription,
  addToHistory,
  removeFromHistory,
} from './redux/actions';
import Result from './Result';

export default function Questions() {
  const [showNextButton, setShowNextButton] = useState(false);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  // const [showStartButton, setShowStartButton] = useState(false);
  const [optionNumber, setOptionNumber] = useState(0);
  const [optionType, setOptionType] = useState('question');
  const [optionAnswer, setOptionAnswer] = useState('');
  const [optionQuestion, setOptionQuestion] = useState('');
  // const [activityDescription, setActivityDescription] = useState('');
  // const [secondPage, setSecondPage] = useState('firstPage');

  const dispatch = useDispatch();

  const history = useSelector((state) => state.questionnaireHistory);
  const currentType = useSelector((state) => state.currentType);
  const currentQuestionNumber = useSelector((state) => state.currentQuestion);

  const currentQuestion = allQuestions.filter(
    (question) => question.id === currentQuestionNumber
  );

  function clearAllRadios() {
    const radioList = document.getElementsByName('options');

    radioList.forEach((rad) => {
      if (rad.checked) {
        rad.checked = false;
      }
    });
  }

  function handleStartButton() {
    dispatch(changeCurrentType('question'));
    // dispatch(changeActivityDescription(activityDescription));
  }

  function handlePreviousButton() {
    history.length === 1 && setShowPreviousButton(false);
    setShowNextButton(false);
    clearAllRadios();
    dispatch(
      removeFromHistory(history[history.length - 1].currentQuestionNumber)
    );

    dispatch(
      changeCurrentQuestion(history[history.length - 1].currentQuestionNumber)
    );
  }

  function handleNextButton() {
    setShowNextButton(false);
    setShowPreviousButton(true);
    dispatch(changeCurrentType(optionType));

    optionType === 'legalBasis' &&
      dispatch(changeCurrentLegalBasis(optionNumber));

    optionType === 'question' && dispatch(changeCurrentQuestion(optionNumber));

    dispatch(
      addToHistory({
        optionQuestion,
        optionAnswer,
        currentQuestionNumber,
      })
    );
    clearAllRadios();
  }

  function arrayAnswers() {
    var options = document.querySelectorAll("input[name='options']");
    var selecionados = [];

    for (var i = 0; i < options.length; i++) {
      if (options[i].checked) {
        selecionados.push(options[i].value + '. ');
      }
    }
    console.log(selecionados);
    return selecionados;
  }

  return (
    <div style={{ padding: '10px', margin: '10px' }}>
      {
        currentType === 'firstPage' && (
          <div>
            <h2
              style={{
                fontSize: '18px',
                textAlign: 'left',
              }}
              className='question'>
              ‘Qual a base legal?’ é a ferramenta desenvolvida pelo escritório
              Prado Vidigal para auxílio à definição de bases legais em
              atividades de tratamento de dados pessoais, conforme a Lei Geral
              de Proteção de Dados (LGPD).
              <br />
              <br />
              Todo e qualquer resultado deve ser encarado somente como mero ponto de partida às devidas análises internas de cada organização à luz das circunstâncias particulares das atividades analisadas, não devendo ser entendido, em hipótese alguma, como parecer ou opinião legal do escritório Prado Vidigal.
            </h2>
            <div
              style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <button
                onClick={handleStartButton}
                style={{
                  ...style2,
                  borderColor: '#ff625f',
                  color: '#ff625f',
                }}>
                INICIAR{' '}
                <i
                  class='fas fa-chevron-circle-right'
                  style={{ fontSize: '20px' }}></i>
              </button>
            </div>
          </div>
        )

        // : (
        //   currentType !== 'question' &&
        //   currentType !== 'legalBasis' && (
        //     <div>
        //       <h2 className='question'>
        //         Descreva a atividade de tratamento de dados pessoais a ser
        //         avaliada, explicando brevemente a finalidade para utilização dos
        //         dados pessoais:
        //       </h2>

        //       <div
        //         style={{
        //           padding: '10px',
        //           display: 'flex',
        //           alignItems: 'center',
        //           justifyContent: 'center',
        //         }}>
        //         <input
        //           style={{ width: '100%', height: '80px' }}
        //           className='option'
        //           type='text'
        //           placeholder='Descreva aqui'
        //           onChange={(e) => {
        //             setActivityDescription(e.target.value);
        //             e.target.value.length > 3 && setShowStartButton(true);
        //             e.target.value.length <= 3 && setShowStartButton(false);
        //           }}
        //         />
        //       </div>
        //       <div
        //         style={{
        //           display: 'flex',
        //           justifyContent: 'center',
        //           padding: '10px',
        //         }}>
        //         {showStartButton ? (
        //           <button
        //             className='button'
        //             onClick={handleStartButton}
        //             style={{
        //               ...style2,
        //               borderColor: '#ff625f',
        //               color: '#ff625f',
        //             }}>
        //             PRÓXIMO
        //             <i
        //               class='fas fa-angle-double-right'
        //               style={{
        //                 fontSize: '19px',
        //                 paddingLeft: '5px',
        //                 paddingRight: '5px',
        //               }}></i>
        //           </button>
        //         ) : (
        //           <button
        //             className='button'
        //             style={{
        //               ...style2,
        //               borderColor: '#ff625f88',
        //               color: '#ff625f88',
        //             }}
        //             disabled>
        //             PRÓXIMO
        //             <i
        //               class='fas fa-angle-double-right'
        //               style={{
        //                 fontSize: '19px',
        //                 paddingLeft: '5px',
        //                 paddingRight: '5px',
        //               }}></i>
        //           </button>
        //         )}
        //       </div>
        //     </div>
        //   )
        // )
      }

      {currentType === 'question' && (
        <>
          <h2 className='question'>
            {currentQuestion[0].question}
            <h2 className='obs'>{currentQuestion[0].obs}</h2>
          </h2>

          {currentQuestion[0].answers.map((answer, id) => (
            <div
              key={id}
              style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <input
                hidden
                type={
                  currentQuestion[0].answers.length > 2 ? 'checkbox' : 'radio'
                }
                name='options'
                value={answer.answer}
                id={answer.goTo[0] + answer.goTo[1] + id}
                onChange={() => {
                  setOptionType(answer.goTo[0]);
                  setOptionNumber(answer.goTo[1]);
                  setShowNextButton(true);
                  setOptionQuestion(currentQuestion[0].question);
                  setOptionAnswer(arrayAnswers(answer.answer));
                }}
              />
              <label
                className='option'
                htmlFor={answer.goTo[0] + answer.goTo[1] + id}>
                {answer.answer}
              </label>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
            }}>
            {showPreviousButton ? (
              <button
                className='button'
                style={{
                  ...style2,
                  borderColor: '#ff625f',
                  color: '#ff625f',
                }}
                onClick={handlePreviousButton}>
                <i
                  class='fas fa-angle-double-left'
                  style={{
                    fontSize: '19px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}></i>
                ANTERIOR
              </button>
            ) : (
              <button
                className='button'
                style={{
                  ...style2,
                  borderColor: '#ff625f88',
                  color: '#ff625f88',
                }}
                disabled>
                <i
                  class='fas fa-angle-double-left'
                  style={{
                    fontSize: '19px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}></i>
                ANTERIOR
              </button>
            )}

            {showNextButton ? (
              <button
                className='button'
                onClick={handleNextButton}
                style={{
                  ...style2,
                  borderColor: '#ff625f',
                  color: '#ff625f',
                }}>
                PRÓXIMO
                <i
                  class='fas fa-angle-double-right'
                  style={{
                    fontSize: '19px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}></i>
              </button>
            ) : (
              <button
                className='button'
                style={{
                  ...style2,
                  borderColor: '#ff625f88',
                  color: '#ff625f88',
                }}
                disabled>
                PRÓXIMO
                <i
                  class='fas fa-angle-double-right'
                  style={{
                    fontSize: '19px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}></i>
              </button>
            )}
          </div>
        </>
      )}

      {currentType === 'legalBasis' && (
        <div
          style={{
            padding: '10px',
          }}>
          <Result />
        </div>
      )}
    </div>
  );
}

const style2 = {
  background: 'transparent',
  borderWidth: '3px',
  borderStyle: 'solid',
  padding: '30px 20px',
  width: '47%',
  fontWeight: 'bold',
  fontSize: '18px',
  cursor: 'pointer',
};
