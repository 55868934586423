import * as actions from '../actions/actionTypes';

export default function currentType(state = 'firstPage', action) {
  switch (action.type) {
    case actions.CHANGE_CURRENT_TYPE:
      return action.payload.type;
    default:
      return state;
  }
}
