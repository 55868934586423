import React from 'react';
import './Header.css';
// import logoApp from './logoPpv.png';

export default function Header() {
  return (
    <div className="noPrint" style={style1}>
      <h1 style={{letterSpacing: '-2px', fontWeight:'bolder'}}>QUAL A BASE LEGAL?
      {/* <img 
          src={logoApp}
          height='40px'
          style={{paddingLeft: '10px'}}
          alt="Logo do escritório de advocacia Prado Vidigal"
      /> */}
      </h1>
    </div>
  );
}

const style1 = {
  padding: '10px',
  margin: '10px',
  display: 'flex',
  color: '#2E382E',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};
