import { combineReducers } from 'redux';
import currentLegalBasis from './currentLegalBasis';
import currentQuestion from './currentQuestion';
import currentType from './currentType';
import questionnaireHistory from './questionnaireHistory';
import activityDescription from './activityDescription';

const allReducers = combineReducers({
  currentLegalBasis,
  currentQuestion,
  questionnaireHistory,
  currentType,
  activityDescription,
});

export default allReducers;
