import * as actions from '../actions/actionTypes';

export default function currentQuestion(state = 1, action) {
  switch (action.type) {
    case actions.CHANGE_CURRENT_QUESTION:
      return action.payload.id;
    default:
      return state;
  }
}
