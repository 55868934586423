import * as actions from './actionTypes';

export const changeCurrentQuestion = (id) => ({
  type: actions.CHANGE_CURRENT_QUESTION,
  payload: {
    id,
  },
});

export const changeCurrentLegalBasis = (id) => ({
  type: actions.CHANGE_CURRENT_LEGAL_BASIS,
  payload: {
    id,
  },
});

export const changeCurrentType = (type) => ({
  type: actions.CHANGE_CURRENT_TYPE,
  payload: {
    type,
  },
});

// export const changeActivityDescription = (description) => ({
//   type: actions.CHANGE_ACTIVITY_DESCRIPTION,
//   payload: {
//     description,
//   },
// });

export const addToHistory = (description) => ({
  type: actions.ADD_TO_HISTORY,
  payload: {
    description,
  },
});

export const removeFromHistory = (id) => ({
  type: actions.REMOVE_FROM_HISTORY,
  payload: {
    id,
  },
});
